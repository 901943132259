





import { Vue, Component, Prop } from 'vue-property-decorator'
import { FormField } from '@/models'
import gql from 'graphql-tag'

@Component({
  apollo: {
    displayValue: {
      query: gql`
        query getOneOfValue($tableId: ID, $fieldName: String, $value: String) {
          displayValue: tableRelationLabel(
            tableId: $tableId
            fieldName: $fieldName
            value: $value
          )
        }
      `,
      variables() {
        return {
          tableId: this.tableId,
          fieldName: this.name,
          value: this.value
        }
      },
      skip() {
        return !this.value
      },
      fetchPolicy: 'network-only'
    }
  }
})
export default class OneOfView extends Vue {
  @Prop({ type: String, default: '' }) value!: string
  /** Field Name */
  @Prop({ type: String, required: true }) name!: string
  /** Field Schema */
  @Prop({ type: Object, default: () => ({}) }) schema!: FormField
  /** Disabled state */
  @Prop({ type: Boolean, default: false }) disabled!: boolean
  /** Environment Variables */
  @Prop({ type: Object, required: true }) environmentVariables!: Record<
    string,
    any
  >

  displayValue = '...'

  get tableId() {
    return this.environmentVariables && this.environmentVariables.tableId
  }

  get environmentId() {
    return this.environmentVariables && this.environmentVariables.environmentId
  }

  get collectionId() {
    return this.schema.options && this.schema.options.collectionId
  }

  get disableRefresh() {
    return this.schema.options && this.schema.options.disableRefresh
  }

  async update() {
    await this.$apollo.queries.displayValue.refetch()
  }
}
