var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[(_vm.schema.newDesign === true)?[_c('label',{staticStyle:{"font-size":"14px"},style:(_vm.schema.isDark === true ? 'color: white' : 'color: #8a8a8a')},[_vm._v(_vm._s(this.schema.label || this.schema.editableLabel || this.name)+" ")]),(false)?_c('v-tooltip',{attrs:{"right":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({attrs:{"small":""}},'v-icon',attrs,false),on),[_vm._v("help ")])]}}],null,false,3383618807)},[_c('span',[_vm._v("Nombre de la tabla")])]):_vm._e(),_c('div',{staticClass:"new-field-component-select"},[_c('v-autocomplete',_vm._b({staticClass:"mt-1",class:_vm.schema.isDark === true ? 'is-dark' : '',attrs:{"prepend-inner-icon":_vm.fieldOptions.hideIcon
            ? undefined
            : _vm.componentType.icon || 'extension',"name":_vm.name,"hint":_vm.schema.description,"required":!_vm.schema.optional,"placeholder":_vm.fieldOptions.placeholder,"rules":[],"error-messages":_vm.errorMessages,"search-input":_vm.searchQuery,"disabled":_vm.disabled,"clearable":!_vm.fieldOptions.readonly,"multiple":_vm.fieldOptions.multi,"loading":_vm.$apollo.loading,"item-text":"name","item-value":_vm.itemValue,"items":_vm.items,"item-color":_vm.componentType.color,"readonly":_vm.fieldOptions.readonly,"color":_vm.componentType.color,"hide-details":"auto","outlined":"","dense":""},on:{"update:searchInput":function($event){_vm.searchQuery=$event},"update:search-input":function($event){_vm.searchQuery=$event},"click:clear":_vm.clear},scopedSlots:_vm._u([(_vm.fieldOptions.multi)?{key:"selection",fn:function(data){return [_c('v-chip',{staticClass:"chip--select-multi",attrs:{"input-value":data.selected,"close":"","small":"","color":_vm.componentType.color,"dark":!!_vm.componentType.color,"disabled":data.item.disabled},on:{"click":function($event){$event.preventDefault();$event.stopPropagation();_vm.componentType.edit && _vm.openComponentEditor(data.item._id)},"click:close":function($event){!data.item.disabled && _vm.remove(data.item._id)}}},[_vm._v(" "+_vm._s(data.item.name)+" ")])]}}:{key:"selection",fn:function(data){return [_c('a',{staticClass:"componentLink",attrs:{"href":_vm.componentType.edit && '#',"to":!_vm.componentType.edit && data.item.href},on:{"click":function($event){$event.preventDefault();$event.stopPropagation();_vm.componentType.edit && _vm.openComponentEditor(data.item._id)}}},[_vm._v(" "+_vm._s(data.item.name || data.item.title || data.item._id)+" ")])]}},{key:"item",fn:function(ref){
            var item = ref.item;
return [_c('v-list-item-avatar',[(item.imgIcon)?_c('img',{attrs:{"src":item.imgIcon}}):_vm._e(),_c('v-icon',{domProps:{"textContent":_vm._s(item.icon || _vm.componentType.icon)}})],1),_c('v-list-item-content',[_c('v-list-item-title',{domProps:{"textContent":_vm._s(item.name || item.title || item._id)}}),_c('v-list-item-subtitle',[_vm._l((item.labels),function(label,i){return _c('v-chip',{key:i,staticStyle:{"padding":"0 0.75em"},attrs:{"color":label.color,"text-color":label.textColor,"small":""},domProps:{"textContent":_vm._s(label.text)}})}),_vm._v(" "+_vm._s(item.subtitle)+" ")],2)],1)]}}],null,true),model:{value:(_vm.currentValue),callback:function ($$v) {_vm.currentValue=$$v},expression:"currentValue"}},'v-autocomplete',_vm.fieldOptions,false),[(_vm.fieldOptions.showChip)?_c('v-chip',{attrs:{"slot":"prepend-inner","small":"","dark":"","color":_vm.componentType.color},slot:"prepend-inner"},[_vm._v(" "+_vm._s(_vm.componentType.titleSingle)+": ")]):_vm._e()],1)],1)]:[_c('v-autocomplete',_vm._b({attrs:{"prepend-icon":_vm.fieldOptions.hideIcon ? undefined : _vm.componentType.icon || 'extension',"prepend-inner-icon":_vm.schema.inner_icon && _vm.fieldOptions.hideIcon
          ? _vm.componentType.icon || 'extension'
          : undefined,"name":_vm.name,"label":_vm.schema.label || _vm.schema.editableLabel || _vm.name,"hint":_vm.schema.description,"required":!_vm.schema.optional,"placeholder":_vm.fieldOptions.placeholder,"rules":[],"error-messages":_vm.errorMessages,"search-input":_vm.searchQuery,"disabled":_vm.disabled,"clearable":!_vm.fieldOptions.readonly,"multiple":_vm.fieldOptions.multi,"loading":_vm.$apollo.loading,"item-text":"name","item-value":_vm.itemValue,"items":_vm.items,"item-color":_vm.componentType.color,"readonly":_vm.fieldOptions.readonly,"color":_vm.componentType.color,"outlined":_vm.schema.outlined,"dense":_vm.schema.dense,"hide-details":"auto"},on:{"update:searchInput":function($event){_vm.searchQuery=$event},"update:search-input":function($event){_vm.searchQuery=$event},"click:clear":_vm.clear},scopedSlots:_vm._u([(_vm.fieldOptions.multi)?{key:"selection",fn:function(data){return [_c('v-chip',{staticClass:"chip--select-multi",attrs:{"input-value":data.selected,"close":"","small":"","color":_vm.componentType.color,"dark":!!_vm.componentType.color,"disabled":data.item.disabled},on:{"click":function($event){$event.preventDefault();$event.stopPropagation();_vm.componentType.edit && _vm.openComponentEditor(data.item._id)},"click:close":function($event){!data.item.disabled && _vm.remove(data.item._id)}}},[_vm._v(" "+_vm._s(data.item.name)+" ")])]}}:{key:"selection",fn:function(data){return [_c('a',{staticClass:"componentLink",attrs:{"href":_vm.componentType.edit && '#',"to":!_vm.componentType.edit && data.item.href},on:{"click":function($event){$event.preventDefault();$event.stopPropagation();_vm.componentType.edit && _vm.openComponentEditor(data.item._id)}}},[_vm._v(" "+_vm._s(data.item.name || data.item.title || data.item._id)+" ")])]}},{key:"item",fn:function(ref){
          var item = ref.item;
return [_c('v-list-item-avatar',[(item.imgIcon)?_c('img',{attrs:{"src":item.imgIcon}}):_vm._e(),_c('v-icon',{domProps:{"textContent":_vm._s(item.icon || _vm.componentType.icon)}})],1),_c('v-list-item-content',[_c('v-list-item-title',{domProps:{"textContent":_vm._s(item.name || item.title || item._id)}}),_c('v-list-item-subtitle',[_vm._l((item.labels),function(label,i){return _c('v-chip',{key:i,staticStyle:{"padding":"0 0.75em"},attrs:{"color":label.color,"text-color":label.textColor,"small":""},domProps:{"textContent":_vm._s(label.text)}})}),_vm._v(" "+_vm._s(item.subtitle)+" ")],2)],1)]}}],null,true),model:{value:(_vm.currentValue),callback:function ($$v) {_vm.currentValue=$$v},expression:"currentValue"}},'v-autocomplete',_vm.fieldOptions,false),[(_vm.fieldOptions.showChip)?_c('v-chip',{attrs:{"slot":"prepend-inner","small":"","dark":"","color":_vm.componentType.color},slot:"prepend-inner"},[_vm._v(" "+_vm._s(_vm.componentType.titleSingle)+": ")]):_vm._e()],1)],(!_vm.fieldOptions.noEdit && _vm.componentType.edit && _vm.componentEditorId)?_c('ComponentEditorDialog',{attrs:{"componentTypeName":_vm.componentTypeName,"environmentId":_vm.environmentVariables.environmentId,"componentId":_vm.componentEditorId},model:{value:(_vm.componentEditorOpen),callback:function ($$v) {_vm.componentEditorOpen=$$v},expression:"componentEditorOpen"}}):_vm._e(),(!_vm.fieldOptions.noCreate && _vm.componentType.create)?_c('ComponentCreateDialog',{attrs:{"environmentId":_vm.environmentVariables.environmentId,"componentTypeName":_vm.componentTypeName},on:{"create":_vm.handleCreate},model:{value:(_vm.createModalOpen),callback:function ($$v) {_vm.createModalOpen=$$v},expression:"createModalOpen"}}):_vm._e()],2)}
var staticRenderFns = []

export { render, staticRenderFns }