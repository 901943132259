import Vue from 'vue'
import Vuex from 'vuex'

import AppModule from './app'
import AuthModule from './auth'
import SnackbarModule from './snackbar'
import DialogsModule from './dialogs'
import DashboardModule from './dashboard'
import SyncModule from './sync'

Vue.use(Vuex)

export interface RootState {
  version: string
}

const initialState: RootState = {
  version: '2.0.0'
}

export default new Vuex.Store({
  state: initialState,
  modules: {
    app: AppModule,
    auth: AuthModule,
    snackbar: SnackbarModule,
    dialogs: DialogsModule,
    dashboard: DashboardModule,
    sync: SyncModule
  }
})
