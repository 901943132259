






























































































































import { Component, Vue } from 'vue-property-decorator'
import { User, Environment } from '@/models'
import getEnv from '@/plugins/getEnv'

@Component({
  name: 'DashboardAppBar'
})
export default class DashboardAppBar extends Vue {
  desktopApp = getEnv('VUE_APP_IS_DESKTOP')

  async logout() {
    await this.$store.dispatch('auth/logout')
    await this.$router.push('/login')
    return
  }

  setDrawer() {
    this.$store.commit(
      'dashboard/setDrawer',
      !this.$store.state.dashboard.drawer
    )
  }

  get userAvatar(): string {
    return this.$store.getters['auth/userAvatarURL']
  }

  get currentView() {
    if (this.$route.name === 'home') {
      return {
        icon: 'home',
        label: 'Inicio'
      }
    }
    if (this.$route.name === 'adminUser') {
      return {
        icon: 'person',
        label: 'Usuario'
      }
    }
    if (this.$route.name!.indexOf('admin') === 0) {
      return {
        icon: 'apps',
        label: this.currentEnvironment ? this.currentEnvironment.name : ''
      }
    }
  }

  get environmentLink() {
    return (
      (this.currentEnvironment ? '//' + this.currentEnvironment.url : '') +
      (this.$store.state.dashboard.environmentLinkPath || '')
    )
  }

  get currentEnvironment() {
    const firstEntry = this.$store.state.app.recents[0]
    if (firstEntry && firstEntry._id === this.envId) {
      return this.$store.state.app.recents[0]
    }
  }

  get envId() {
    return this.$route.params.environmentId
  }

  get user(): User {
    return this.$store.state.auth.user
  }

  get userRoleDisplay(): string {
    if (!this.user) return ''
    if (this.user.roles.includes('superAdmin')) {
      return 'Súper Administrador'
    }
    if (this.user.roles.includes('admin')) {
      return 'Administrador'
    }
    return 'Usuario'
  }

  changeEnvironment(environmentId: any) {
    if (this.$route.fullPath.indexOf('collections') >= 0) {
      return this.$router.push(`/${environmentId}/collections/`)
    } else if (this.$route.fullPath.indexOf('components') >= 0) {
      return this.$router.push(
        `/${environmentId}/components/${this.$route.params.componentTypeName}`
      )
    } else if (this.$route.fullPath.indexOf('design') >= 0) {
      return this.$router.push(`/${environmentId}/design/`)
    } else if (this.$route.fullPath.indexOf('settings') >= 0) {
      return this.$router.push({
        name: this.$route.name || 'admin',
        params: {
          environmentId
        }
      })
    } else {
      return this.$router.push(`/${environmentId}/`)
    }
  }

  toggleDarkMode() {
    this.$store.commit('app/toggleDarkMode')
  }
}
