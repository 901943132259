import { ComponentType, Link, LinkType } from '@/models'
import _snakeCase from 'lodash/snakeCase'
import asyncImport from '@/utils/client/asyncImport'

const normalizedIcon = (name: string | undefined) => {
  const icon = name || ''
  return icon.startsWith('Md') ? _snakeCase(icon.slice(2)) : icon
}

const getSubtitle = (link: Partial<Link>) => {
  switch (link.type) {
    case 'path':
      return link.path
    case 'category':
      const fields = link.fields ? link.fields : []
      return fields.map((f) => f.title).join(', ')
    default:
      return ' '
  }
}

export const links = {
  name: 'link',
  namespace: 'links',
  title: 'Links',
  titleSingle: 'Link',
  description: 'Permite enlazar hacia una vista desde la sidebar',
  icon: 'attachment',
  overview:
    'Los Links son las rutas que estarán disponibles en nuestro proyecto o ambiente de trabajo. Podemos crear rutas visibles solo para determinados usuarios, para esto utilizamos los roles. Los links serán visibles en el menú de navegación del proyecto.',

  queryName: 'links',
  queryFields: `{
    _id
    environmentId
    title
    type
    icon
    path
    fields {
      title
    }
  }`,
  searchable: false,
  hidden: true,

  transformResult: (link) => ({
    ...link,
    title: link.title,
    subtitle: getSubtitle(link),
    icon: normalizedIcon(link.icon),
    labels: [
      link.type
        ? {
            path: {
              text: 'Ruta',
              color: 'blue',
              textColor: 'white'
            },
            category: {
              text: 'Categoría',
              color: 'cyan',
              textColor: 'white'
            },
            userMenu: {
              text: 'Menú',
              color: 'green',
              textColor: 'white'
            }
          }[link.type || LinkType.Single]
        : { text: 'Sin configurar' }
    ],
    href: `/${link.environmentId}/components/links/${link._id}`
  }),

  create: () => asyncImport(import('./Create.vue')),
  edit: () => asyncImport(import('./Edit.vue'))
} as ComponentType<Link>
