import mime from 'mime-types'
import _deburr from 'lodash/deburr'
import gql from 'graphql-tag'
import getClient from '@/plugins/vue-apollo/client'
import { DocumentNode } from 'apollo-link'
import { FileCredentialsResult } from '../models/FileCredentails'

/**
 *  File Upload Service, contains all functions necesary to perform a full upload, except for the physical method to get the file to S3 (check: "physicallyUploadFileToS3" method in Vue Components),
 *  this is because of the use of a progress bar within the vue components
 */
export default class FileUploadService {
  fileFragment: DocumentNode

  /**
   *    @param fragment: DocumentNode
   */
  constructor(private fragment?: DocumentNode) {
    if (!fragment) {
      this.fileFragment = gql`
        fragment FileManagerFile on File {
          _id
          genericType
          name
          url
          type
          size
          key
          bucket
          status
          createdBy
          createdAt
        }
      `
    } else {
      this.fileFragment = fragment
    }
  }

  client = getClient()

  /**
   *    Generates Parsed & Clean filename, uses Lodash "_deburr" function.
   *    @param name: string
   *    @returns string
   */
  generateFileName(name: string): string {
    return _deburr(name.toLowerCase().replace(/[^\w\d]/g, ''))
  }

  /**
   * Checks if file type is valid
   * @param file: File
   * @param fileType: string
   * @returns Boolean
   */
  isValidFile(file: File, fileType: string): Boolean {
    return fileType !== undefined && file.type.indexOf(fileType) !== 0
  }

  /**
   * Gets file metadata using Apollo Client
   * @param file: File
   * @returns Record<any> | Promise<any>
   */
  async getFileMetadata(file: File) {
    const response = await this.client.mutate({
      mutation: gql`
        mutation generateUploadCredentials(
          $name: String
          $size: Float
          $type: String
        ) {
          result: generateUploadCredentials(
            name: $name
            size: $size
            type: $type
          ) {
            fileId
            url
            fields
            key
          }
        }
      `,
      variables: {
        name: file.name,
        size: file.size,
        type: file.type || mime.lookup(file.name) || 'application/octet-stream'
      }
    })

    return response.data
  }

  /**
   * Signals a complete file upload using Apollo Client
   * @param destination: FileCredentialsResult
   * @returns Record | Promise
   */
  async completeUpload(destination: FileCredentialsResult) {
    const response = await this.client.mutate({
      mutation: gql`
        mutation completeUpload($fileId: ID) {
          uploadedFile: completeUpload(fileId: $fileId) {
            ...FileManagerFile
          }
        }
        ${this.fileFragment}
      `,
      variables: {
        fileId: destination.fileId
      }
    })

    return response
  }
}
