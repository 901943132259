




























































































































import { Component, Vue, Prop, Watch } from 'vue-property-decorator'
import CodelessLoading from '@/components/CodelessLoading.vue'
import MutationForm from '@/components/form/MutationForm.vue'
import errorBg from '@/assets/error_bg.jpg'
import defaultBg from '@/assets/login_bg0.jpg'
import defaultLogo from '@/assets/icono_horizontal.svg'
import { Environment, Session } from '@/models'
import gql from 'graphql-tag'
import { ApolloError } from 'apollo-client'
import StatusBar from '@/components/mobileApp/StatusBar.vue'
import { alert } from '@/components/dialogs'
import getEnv from '@/plugins/getEnv'

@Component({
  name: 'Login',
  components: {
    CodelessLoading,
    MutationForm,
    StatusBar
  },
  apollo: {
    environment: {
      query: gql`
        query getEnvironment($url: String) {
          environment(url: $url) {
            _id
            name
            accentColor
            useCustomCss
            customCss
            analyticsId
            logo {
              url
            }
            loginLogo {
              url
            }
            authBackgroundImage {
              url
            }
          }
        }
      `,
      variables() {
        return {
          url: this.domain
        }
      },
      skip() {
        return !this.domain
      }
    }
  }
})
export default class NewLogin extends Vue {
  @Prop({ type: String, default: '' }) domain!: string
  desktopApp = !!getEnv('VUE_APP_IS_DESKTOP')
  environment: Partial<Environment> | null = null

  email = ''
  password = ''
  authenticating = true
  forgot = false

  @Watch('environment')
  async setEnvironmentVariables(env: Environment) {
    if (!env) return
    const style = document.getElementById('customCSS') as HTMLStyleElement
    if (env.accentColor) {
      this.$vuetify.theme.themes.light.primary =
        this.$vuetify.theme.themes.light.secondary =
        this.$vuetify.theme.themes.light.accent =
          env.accentColor
      this.$vuetify.theme.themes.dark.primary =
        this.$vuetify.theme.themes.dark.secondary =
        this.$vuetify.theme.themes.dark.accent =
          env.accentColor
    }
    if (env.useCustomCss && env.customCss) {
      // style.innerText = env.customCss
    } else {
      // style.innerText = ''
    }
    if (env.analyticsId) {
      gtag('config', env.analyticsId)
    }
  }

  get loggedIn() {
    return this.$store.getters['auth/loggedIn']
  }

  get user() {
    return this.$store.state.auth.user
  }

  get userAvatar() {
    return this.$store.getters['auth/userAvatarURL']
  }

  get logo() {
    if (this.$apollo.loading) return ''
    if (this.environment && this.environment.loginLogo)
      return this.environment.loginLogo.url
    if (this.environment && this.environment.logo)
      return this.environment.logo.url
    return defaultLogo
  }

  get bgStyle() {
    let background
    if (this.environment && this.environment.authBackgroundImage) {
      background = `url(${this.environment.authBackgroundImage.url}) fixed center / cover`
    } else if (!this.$apollo.loading && this.domain && !this.environment) {
      background = `url(${errorBg}) fixed center / cover`
    } else if (!this.$apollo.loading) {
      background = `url(${defaultBg}) fixed center / cover`
    }
    return {
      background,
      padding: 0
    }
  }

  mounted() {
    return this.resumeSession()
  }

  forgotSuccess() {
    alert(
      'Por favor sigue las instrucciones del correo que te enviamos (revisa también tu carpeta de SPAM si no llega a la bandeja de entrada)'
    ).catch()
    this.forgot = false
  }

  async resumeSession() {
    const authenticated = await this.$store.dispatch('auth/resumeSession')
    if (authenticated) {
      await this.$router.replace((this.$route.query.to as string) || '/')
    } else {
      if (this.desktopApp) {
        this.autoLoginDemoAccount()
      } else {
        this.authenticating = false
      }
    }
  }

  async autoLoginDemoAccount() {
    const { data } = await this.$apollo.mutate({
      mutation: gql`
        mutation loginWithPassword($email: String, $password: String) {
          result: loginWithPassword(email: $email, password: $password) {
            _id
            publicKey
            secretKey
            createdAt
            nonce
            lastCall
            userId
            locale
            roles
            emailVerified
            options
          }
        }
      `,
      variables: {
        email: 'demo@codeless.biz',
        password: 'codeless'
      }
    })
    this.loginSuccess(data.result)
  }

  async loginSuccess(session: Session) {
    this.authenticating = true
    await this.$store.dispatch('auth/loadSession', session)
    await this.$router.push((this.$route.query.to as string) || '/')
  }
}
