




























































import { Component, Vue, Prop } from 'vue-property-decorator'
import { FormField } from '@/models'
import moment from '@/plugins/moment'

interface DateTimeFieldOptions {
  min?: number
  max?: number
  previousDays?: number
  nextDays?: number
  integer?: boolean
  placeholder?: string
}

@Component({})
export default class DateTimeField extends Vue {
  /** Current Value */
  @Prop({ type: String }) value!: string | null
  /** Validation Errors */
  @Prop() errorMessages!: string | string[]
  /** Field Name */
  @Prop({ type: String, required: true }) name!: string
  /** Field Schema */
  @Prop({ type: Object, default: () => ({}) }) schema!: FormField
  /** Disabled state */
  @Prop({ type: Boolean, default: false }) disabled!: boolean

  open = false

  /** Validation Rules */
  get validationRules() {
    const rules = []
    // Required validation
    if (!this.schema.optional) {
      rules.push((v?: number | null) => !!v || 'Campo Requerido.')
    }

    return rules
  }

  get prevDays() {
    if (
      this.fieldOptions.previousDays == null ||
      this.fieldOptions.previousDays < 0
    )
      return
    return moment
      .tz(new Date(), 'America/Santiago')
      .subtract(this.fieldOptions.previousDays, 'days')
      .toISOString()
  }

  get nextDays() {
    if (this.fieldOptions.nextDays == null || this.fieldOptions.nextDays < 0)
      return
    return moment
      .tz(new Date(), 'America/Santiago')
      .add(this.fieldOptions.nextDays, 'days')
      .toISOString()
  }

  get dateValue() {
    return this.value ? moment(this.value).format('YYYY-MM-DD') : null
  }

  set dateValue(value: string | null) {
    this.$emit('input', value ? this.parseDate(value, this.timeValue) : null)
  }

  get timeValue() {
    return this.value ? moment(this.value).format('HH:mm') : null
  }

  set timeValue(value: string | null) {
    this.$emit('input', this.parseDate(this.dateValue, value))
  }

  get displayValue() {
    return this.value ? moment(this.value).format('DD-MM-YYYY HH:mm') : ''
  }

  /** Additional field options */
  get fieldOptions(): DateTimeFieldOptions {
    return this.schema.options || this.schema.fieldOptions || {}
  }

  parseDate(date: string | null, time: string | null) {
    return moment(
      `${date || moment().format('YYYY-MM-DD')} ${time || '00:00'}`,
      'YYYY-MM-DD HH:mm'
    ).toISOString()
  }

  clear() {
    this.value = null
  }
}
